<template>
    <div class="page-box s-page">
      <div class="s-header">
        <van-nav-bar
          :title="$t('mining.title')"
          left-arrow
          border
          @click-left="onClickLeft"
        >
        </van-nav-bar>
      </div>
      <div class="maincontent s-content">
        <div class="title1">
          <span>{{$t('mining.start-h1')}}</span>
        </div>
        <div class="title2">{{$t('mining.start-h2')}}</div>
        <div class="card-box">
          <div class="card-title">
            {{$t('mining.start-card1-t')}}
          </div>
          <van-row class="advantages">
            <van-col span="12">
              <div>{{$t('mining.start-card1-c1')}}</div>
              <div>{{$t('mining.start-card1-c2')}}</div>
            </van-col>
            <van-col span="12">
              <div>{{$t('mining.start-card1-c3')}}</div>
              <div>{{$t('mining.start-card1-c4')}}</div>
            </van-col>
          </van-row>
          <van-row gutter="40" class="advantagesDetailed">
            <van-col span="8">
              <van-image width="2.88rem" height="2.88rem" :src="require('../../assets/img/anquan.png')" />
              <div>{{$t('mining.start-card1-c5')}}</div>
            </van-col>
            <van-col span="8">
              <van-image width="2.88rem" height="2.88rem" :src="require('../../assets/img/qiandai.png')" />
              <div>{{$t('mining.start-card1-c6')}}</div>
            </van-col>
            <van-col span="8">
              <van-image width="2.88rem" height="2.88rem" :src="require('../../assets/img/yingyong.png')" />
              <div>{{$t('mining.start-card1-c7')}}</div>
            </van-col>
          </van-row>
        </div>
        <div class="card-box">
            <div class="card-title">{{$t('mining.start-card2-t')}}</div>
            <div class="card-des">{{$t('mining.start-card2-c1')}}</div>
            <div class="card-des">{{$t('mining.start-card2-c2')}}</div>
        </div>
        <div class="card-box">
            <div class="card-title" style="margin-bottom: 0.66rem;">{{$t('mining.start-card3-t')}}</div>
            <div class="card-des">{{$t('mining.start-card3-c')}}</div>
        </div>
        <div class="bottom-box">
          <div class="btn" @click="$router.push('/miningProjectList')">{{$t('mining.title')}}</div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  created() {
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="less" scoped>
.maincontent {
  background: url("~@/assets/img/mining/bg.png") no-repeat;
  background-size: 100% 100%;
  padding: 1.25rem;
  .title1{
    margin-bottom: 0.8125rem;
    span {
      background: #164FC3;
      font-weight: 500;
      font-size: 1rem;
      color: #FBFCF9;
      padding: 0.5rem 1.5625rem 0.6875rem;
      display: inline-block;
      border-radius: 0.3125rem;
      position: relative;
      &:before {
        content: "";
        position: absolute;
        width: 46px;
        height: 23px;
        background: url("~@/assets/img/mining/title-left.png");
        background-size: cover;
        top: 2px;
        left: -43px;
      }
      &:after {
        content: "";
        position: absolute;
        width: 62px;
        height: 18px;
        background: url("~@/assets/img/mining/title-right.png");
        background-size: cover;
        top: 9px;
        right: -60px;
      }
    }
  }
  .title2{
    font-weight: 400;
    font-size: 0.75rem;
    color: #FFFFFF;
    line-height: 1rem;
    margin-bottom: 0.9375rem;
  }
  .card-box{
    padding: 1.38rem 0.88rem;
    width: 100%;
    background: #14162B;
    border-radius: 0.38rem;
    position: relative;
    margin-bottom: 1.25rem;
    background-image: url("~@/assets/img/mining/border-left.png"), url("~@/assets/img/mining/border-right.png");
    background-repeat: no-repeat, no-repeat;
    background-position: left top, right bottom;
    background-size: 70%, 70%;
  }
  .card-title{
    font-weight: 800;
    font-size: 1rem;
    color: #FDFDFF;
    margin-bottom: 1.25rem;
  }
  .advantages {
    font-weight: 500;
    font-size: 0.875rem;
    color: #FDFDFF;
    line-height: 1.3rem;
    margin-bottom: 0.9375rem;
  }
  .advantagesDetailed {
    font-weight: 400;
    font-size: 0.75rem;
    color: #FDFDFF;
    line-height: 1rem;
    .van-image{
      margin-bottom: 0.875rem;
    }
  }
  .card-des{
    font-weight: 400;
    font-size: 0.75rem;
    color: #FDFDFF;
    line-height: 1.3625rem;
    text-align: left;
  }
}
.bottom-box{
  bottom: 2.5rem;
  width: 100%;
  padding: 0.6875rem 0;
  background: url("~@/assets/img/mining/btn.png") no-repeat;
  background-size: 100%;
  .btn{
    font-weight: 500;
    font-size: 1.125rem;
    color: #FFFFFF;
  }
}
</style>
